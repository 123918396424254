import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { lazyImport } from '@pv/common/utils';
import {
  ServerStateProvider,
  ThemeProvider,
  SnackbarProvider,
} from '@pv/common/providers';
import { ErrorBoundary, PageLoading } from '@pv/common/components';
import { PVSnackbarProvider } from './SnackbarProvider';
import './App.css';
import { apiHost } from '@pv/common/api';
const pvEnv = process.env.REACT_APP_PV_ENV as string;
const API_HOST = apiHost(pvEnv);

const ContactForm = lazyImport(() => import('./pages/ContactForm'));
const CollectionSearchResults = lazyImport(
  () => import('./pages/CollectionSearchResults')
);
const CollectionGroupSizeFilter = lazyImport(
  () => import('./pages/CollectionGroupSizeFilter')
);

function App() {
  return (
    <ErrorBoundary>
      <ServerStateProvider apiHost={API_HOST}>
        <ThemeProvider>
          <PVSnackbarProvider>
            <BrowserRouter>
              <Suspense fallback={<PageLoading />}>
                <Routes>
                  <Route
                    path="/contact-form/:widgetInstanceId"
                    element={<ContactForm />}
                  />
                  <Route
                    path="/collectives/:collectiveId/search-results"
                    element={<CollectionSearchResults />}
                  />
                  <Route
                    path="/collectives/:collectiveId/group-size-filter"
                    element={<CollectionGroupSizeFilter />}
                  />
                </Routes>
              </Suspense>
            </BrowserRouter>
          </PVSnackbarProvider>
        </ThemeProvider>
      </ServerStateProvider>
    </ErrorBoundary>
  );
}

export default App;
