import React from 'react';
import { IconButton } from '@mui/material';
import { X as CloseIcon } from 'react-feather';
import { styled } from '@mui/material/styles';
import {
  SnackbarProvider,
  MaterialDesignContent,
  closeSnackbar,
} from 'notistack';
import { SnackbarProvider as CommonSnackbarProvider } from '@pv/common/providers';

const StyledMaterialDesignContent = styled(MaterialDesignContent)(
  ({ theme }) => ({
    '&.notistack-MuiContent-success': {
      backgroundColor: theme.palette.success.main,
    },
    '&.notistack-MuiContent-info': {
      backgroundColor: theme.palette.primary.main,
    },
    '&.notistack-MuiContent-error': {
      backgroundColor: theme.palette.error.main,
    },
    '&.notistack-MuiContent-warning': {
      backgroundColor: theme.palette.warning.main,
    },
    '&.notistack-MuiContent-default': {
      backgroundColor: theme.palette.primary.main,
    },
  })
);

const LocalSnackbarProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const notistackAction = (key: string) => {
    const onClick = () => closeSnackbar(key);
    return (
      <IconButton onClick={onClick}>
        <CloseIcon className="snackbar-close" style={{ color: 'white' }} />
      </IconButton>
    );
  };

  return (
    <SnackbarProvider
      maxSnack={2}
      action={notistackAction}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      Components={{
        info: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
        default: StyledMaterialDesignContent,
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

export const PVSnackbarProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <>
      <CommonSnackbarProvider />
      <LocalSnackbarProvider />
      {children}
    </>
  );
};
